<script>
import AppLayout from '@/layouts/AppLayout.vue';
import headerMessageLogin from '@/components/header/messages/headerMessageLogin.vue';
import headerMessageLocation from '@/components/header/messages/headerMessageLocation.vue';
import headerMessageBuy from '@/components/header/messages/headerMessageBuy.vue';
import { mapState } from 'vuex';
import LogService from '@/services/LogService';

export default {
  extends: AppLayout,
  components: {
    headerMessageLogin,
    headerMessageLocation,
    headerMessageBuy,
  },
  data() {
    return {
      links: {
        home: `/home/${this.$route.params?.lang || ''}`,
      },
    };
  },
  computed: {
    ...mapState(['userStatus', 'pageTitle', 'appLayout']),
    hasDrawer() {
      return false;
    },
    headerAttrs() {
      return {
        title: this.pageTitle,
        containered: true,
        skeleton: ['title', 'homeIcon'],
        homeIcon: `
<svg id='icon-home' viewBox='0 0 24 24'>\
  <path d='M2.39 8.21l9-7a1 1 0 0 1 1.23 0l9 7A1 1 0 0 1 22 9v11a3 3 0 0 1-3 3H5a3 3 0\
    0 1-3-3V9a1 1 0 0 1 .39-.79zM14 21v-8h-4v8zm2 0h3a1 1 0 0 0 1-1V9.49l-8-6.22-8 6.22V20a1\
    1 0 0 0 1 1h3v-9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z'></path>\
</svg>`,
        progress: this.appLayout.progress,
      };
    },
    headerMessageComponent() {
      let component = '';
      LogService.log(
        'checking user status to see if need to show login link. user status',
        this.userStatus,
      );
      console.log(this.userStatus);
      if ((['additional', 'buy-vacation'].includes(this.$route.name) && ['3', '5'].includes(this.userStatus)) || ['letus'].includes(this.$route.name)) {
        component = 'headerMessageLogin';
      } else if (this.$route.name === 'location') {
        component = 'headerMessageLocation';
      } else if (this.$route.name === 'buy' && this.totalInHeader) {
        component = 'headerMessageBuy';
      }
      return component;
    },
    imprintAbsolute() {
      return this.$route.name === 'location';
    },
  },
};
</script>
