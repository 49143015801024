/* eslint no-underscore-dangle: 0 */
/* eslint-disable max-classes-per-file */
import ValidationItem from '@/models/validation-item';
import LogService from '@/services/LogService';

class Validation {
  constructor(data = []) {
    const entries = data.map((validation) => [
      validation.subtype || validation.type,
      new ValidationItem(validation),
    ]);

    entries.forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

class CropsSettings {
  constructor(data = {}) {
    this.crop_id = data.id;
    this.default_end = data.default_end_date;
    this.default_payout = data.default_payout;
    this.default_start = data.default_start_date;
    this.id = data.subtype;
    this.title = data.subtype;
    this.default_crop_value_per_hectar = data.default_crop_value_per_hectar === null
      || Number.isNaN(Number(data.default_crop_value_per_hectar))
      ? null : Number(data.default_crop_value_per_hectar);
    this.default_area = 10;
  }
}

export default ({
  namespaced: true,
  state: {
    crops: [],
    calendar: {},
    menu: [],
  },
  mutations: {
    SET_CROPS(state, data) {
      state.crops = data;
    },
    SET_CALENDAR(state, data) {
      state.calendar = data;
    },
    SET_MENU(state, data) {
      state.menu = data;
    },
  },
  actions: {
    async getProductSettings({ commit, rootState }, site) {
      const siteParam = site || 'custom';
      const url = `${process.env.VUE_APP_API_SERVER}/api/product/settings/?site=${siteParam}`;
      const headers = {
        Authorization: process.env.VUE_APP_API_KEY,
        lang: rootState.lang,
      };
      if (rootState.token) headers.token = rootState.token;

      await this._vm.$axios.get(url, { headers })
        .then((res) => {
          LogService.log('calendar data', res.data);
          commit('SET_TOKEN', res.headers.token, { root: true }); /** set token */
          if (['drought', 'drought2'].includes(site)) commit('SET_CROPS', res.data.map((data) => new CropsSettings(data)) || []);
          commit('SET_CALENDAR', new Validation(res.data));
        });
    },
    async getDerTourPromotionCalendar({ commit }) {
      const data = [
        {
          id: 17,
          type: 'vacation',
          subtype: null,
          weather_direction: 'rainy',
          min_contract_start_postponement_less_29: 14,
          min_contract_start_postponement: 28,
          max_contract_start_postponement: 365,
          min_contract_length: 1,
          max_date_range: 28,
          dates_adjacent: true,
          hours_required: true,
          default_start_date: null,
          default_end_date: null,
          min_hours: 8,
          max_hours: 8,
          default_start_hour: 10,
          default_end_hour: 18,
          min_area: null,
          max_area: null,
          min_crop_value_per_hectar: null,
          max_crop_value_per_hectar: null,
          default_crop_value_per_hectar: null,
        },
      ];
      commit('SET_CALENDAR', new Validation(data));
    },
  },
});
