import configEnv from '@/enums/configEnv';
import PartnerCode from '@/enums/partnerCode';

const setLayout = {
  methods: {
    setCssVariables(
      primary = configEnv.primary_color,
      secondary = configEnv.secondary_color,
      tertiary = configEnv.tertiary_color,
      quaternary = configEnv.quaternary_color,
      quinary = configEnv.quinary_color,
      senary = configEnv.senary_color,
      septenary = configEnv.septenary_color,
      padding_horizontal_input_fields = configEnv.padding_horizontal_input_fields,
      border_radius = configEnv.border_radius,
      border_input_color = configEnv.border_input_color,
    ) {
      const root = document.documentElement;
      if (root) {
        root.style.setProperty('--primary-rgb', this.colorHexToRgb(primary));
        root.style.setProperty('--secondary-rgb', this.colorHexToRgb(secondary));
        root.style.setProperty('--primary', primary);
        root.style.setProperty('--secondary', secondary);
        root.style.setProperty('--tertiary', tertiary);
        root.style.setProperty('--quaternary', quaternary);
        root.style.setProperty('--quinary', quinary);
        root.style.setProperty('--senary', senary);
        root.style.setProperty('--septenary', septenary);
        root.style.setProperty('--padding-horizontal-input-fields', padding_horizontal_input_fields);
        root.style.setProperty('--border-radius', border_radius);
        root.style.setProperty('--border-input-color', border_input_color);
      }
    },
    colorHexToRgb(color) {
      const hexColor = color.replace('#', '');
      const [r, g, b] = [0, 2, 4].map((i) => hexColor.slice(i, i + 2));
      const grbInt = [r, g, b].map((c) => parseInt(c, 16));
      return grbInt.join(', ');
    },
    isSportVersLayout(partnerCode) {
      return configEnv.server_type === 'Wetterheld'
        && [PartnerCode.sportvers, PartnerCode.tomDick].includes(partnerCode);
    },
    setLayoutByPartnerCode(partnerCode) {
      if (this.isSportVersLayout(partnerCode)) {
        this.setCssVariables('#AE1022');
      }
    },
    getVacationBackgroundImage(partnerCode, vacationStrings) {
      if (this.isSportVersLayout(partnerCode)) {
        return vacationStrings.background_image_sportvers.sizes;
      }
      return vacationStrings.background_image.sizes;
    },
    getVacationPartnerLogo(partnerCode, vacationStrings) {
      if (this.isSportVersLayout(partnerCode)) {
        return vacationStrings.logo_sportvers.sizes.medium;
      }
      return '';
    },
    updateVacationString(partnerCode, vacationStrings) {
      if (this.isSportVersLayout(partnerCode)) {
        const vStringsNew = vacationStrings;
        vStringsNew.offer_text = vacationStrings.offer_text_sportvers;
        vStringsNew.section_title = vacationStrings.section_title_sportvers;
        return vStringsNew;
      }
      return vacationStrings;
    },
  },
};

export default setLayout;
