import LogService from '@/services/LogService';
import { camelCase } from 'lodash';
import wpSection from '@/enums/wpSection';

class WPAPIService {
  constructor(axios, store) {
    this.axios = axios;
    this.s = store;
  }

  async createPromises(sections, lang) {
    LogService.log('createPromises');
    const res = await Promise.all(sections.map((section) => this.getStrings(section, lang)));
    LogService.log('createPromises finished');

    return res;
  }

  async getStringsArray(sections, lang) {
    sections.push(wpSection.COMMON_APP);
    const sectionsToDownload = sections.filter(
      (section) => !this.s.state?.strings || !Object.keys(this.s.state.strings).includes(section),
    );
    LogService.log('sectionsToDownload', sectionsToDownload);
    if (sectionsToDownload.length > 0) {
      const response = await this.createPromises(sectionsToDownload, lang);
      LogService.log('getStringsArray', response);
      response.forEach((element, index) => {
        this.s.commit('SET_STRINGS', {
          group: camelCase(sectionsToDownload[index]),
          strings: element.data.acf,
        });
      });
    }
    const result = {};
    sections.forEach((section) => {
      LogService.log('save section', section);
      result[camelCase(section)] = this.s.state.strings[camelCase(section)];
    });
    LogService.log('getStringsArray finished', result);
    return result;
  }

  getStrings(section, lang) {
    LogService.log('getStrings, in store not found section', section);
    LogService.log('getStrings, lang parameter', lang);
    const language = lang === '' ? '' : (`/${lang}`);
    const urlC = `${process.env.VUE_APP_WP_API_SERVER + language}/wp-json/acf/v3/options/${section}/`;
    try {
      const res = this.axios.get(urlC);
      LogService.log('getStrings, get', urlC, res);
      return res;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default WPAPIService;
