const arrToSrcset = (arr) => {
  /* return array of wp image sizes to srcset string
  * arr - array
  */
  let result = '';
  if (arr) {
    ['large', 'medium', 'thumb'].forEach((size) => {
      const widthIndex = `${size}-width`;
      result += `${arr[size]} ${arr[widthIndex]}w, `;
    });
  }
  return result;
};

const other = 'other';

export { arrToSrcset, other };
