/* eslint no-underscore-dangle: 0 */
import Vue from 'vue';
import Vuex from 'vuex';
import LogService from '@/services/LogService';
import configEnv from '@/enums/configEnv';
import settings from './settings';
import partnerCodeModule from './partner-code';

Vue.use(Vuex);

const allowedLangs = configEnv.allowed_langs.split('|');
const langs = allowedLangs.map((lang) => ({
  title: lang.charAt(0).toUpperCase() + lang.slice(1),
  lang,
  icon: `${process.env.VUE_APP_WP_API_SERVER}/wp-content/plugins/wp-multilang/flags/${lang}.png`,
  href: `/home/${lang}/drought/`,
}));
LogService.log('store/index', langs);

export default new Vuex.Store({
  /*
    @lang - current lang
   */
  state: {
    token: sessionStorage.getItem('token') || null,
    menu: [],
    langs,
    countries: [
      {
        name: 'germany',
        code: 'DE',
        title: 'Deutschland',
      },
    ],
    lang: '',
    logo: {
      image: `${process.env.VUE_APP_WP_API_SERVER}/wp-content/themes/wetter/assets/images/${configEnv.logo_file}`,
      href: `${window.location.origin}/home/de/${configEnv.logo_redirect_page}`,
    },
    browser: '',
    loading: [],
    appLayout: {},
    pageTitle: '',
    meta: {},
    strings: {},
    userStatus: null, /* additionalUserInfoStatusRegistration */
    bottomBarPrependMessage: null, /** message in bottombar prepend slot */
    countryTitle: {
      germany: 'Deutschland',
    },
    order: {},
    devMessage: [], /** message for api call errors only in dev mode */
    modals: [], /** for layout modals system */
    breakpoint: {}, /** setted in App.vue */
    cropSelected: {}, /** for page crop selection && yourChoose component */
    isMobile: false,
    colors: {
      primary: configEnv.primary_color,
      secondary: configEnv.secondary_color,
      tertiary: configEnv.tertiary_color,
      quaternary: configEnv.quaternary_color,
      quinary: configEnv.quinary_color,
      senary: configEnv.senary_color,
      septenary: configEnv.septenary_color,
    },
    intro: null,
  },
  mutations: {
    SET_LANG(state, data) {
      state.lang = data;
    },
    SET_MENU(state, data) {
      state.menu = data;
    },
    SET_BROWSER(state, data) {
      state.browser = data;
    },
    SET_LOADING(state, id) {
      LogService.log('set loading triggered', id);
      state.loading.push({ id });
    },
    DEL_LOADING(state, id) {
      state.loading = state.loading.filter((el) => el.id !== id);
      LogService.log('the loading object is', state.loading, 'the removed id is', id);
    },
    RESET_LOADING(state) {
      LogService.log('reset loading');
      state.loading = [];
    },
    SET_APP_LAYOUT(state, data) {
      state.appLayout = data;
    },
    SET_STRINGS(state, { group, strings }) {
      // LogService.log('missing language', this.lang, state.strings);
      // if (!Object.keys(state.strings).includes(this.lang)) {
      //   LogService.log('missing language if');
      // }
      state.strings = {
        ...state.strings,
        [group]: strings,
      };
    },
    CLEAR_STRINGS(state) {
      state.strings = {};
    },
    SET_META(state, data) {
      state.meta = data;
    },
    SET_USER_STATUS(state, data) {
      state.userStatus = data;
    },
    SET_BOTTOMBAR_PREPEND_MESSAGE(state, data) {
      state.bottomBarPrependMessage = data;
    },
    SET_ORDER(state, data) {
      state.order = data;
    },
    ADD_DEV_MESSAGE(state, data) {
      state.devMessage.push(data);
    },
    DEL_DEV_MESSAGE(state) {
      state.devMessage = [];
    },
    ADD_MODAL(state, data) {
      state.modals.push(data);
    },
    DEL_MODAL(state, modal) {
      if (!modal.uuid) {
        console.log('cant del this modal, because needed UUID');
        return;
      }
      state.modals = state.modals.filter((el) => el.uuid !== modal.uuid);
    },
    SET_BREAKPOINT(state, bp) {
      state.breakpoint = bp;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      sessionStorage.setItem('token', token);
    },
    SET_CROP_SELECTED(state, selected) {
      state.cropSelected = selected;
    },
    SET_IS_MOBILE(state, data) {
      state.isMobile = data;
    },
    SET_COLORS(state, data) {
      state.colors = data;
    },
    SET_INTRO(state, intro) {
      state.intro = intro;
    },
    SET_PAGE_TITLE(state, name) {
      state.pageTitle = name;
    },
  },
  actions: {
    checkBrowser({ commit }) {
      // check browser
      const ua = window.navigator.userAgent;
      let browser = '';

      // get browser & set it to store
      if (ua.indexOf('Firefox') > -1) {
        browser = 'Mozilla Firefox';
      } else if (ua.indexOf('Opera') > -1) {
        browser = 'Opera';
      } else if (ua.indexOf('Trident') > -1) {
        browser = 'Microsoft Internet Explorer';
      } else if (ua.indexOf('Edge') > -1) {
        browser = 'Microsoft Edge';
      } else if (ua.indexOf('Chrome') > -1) {
        browser = 'Google Chrome or Chromium';
      } else if (ua.indexOf('Safari') > -1) {
        browser = 'Apple Safari';
      } else {
        browser = 'unknown';
      }
      commit('SET_BROWSER', browser);
    },
    updLang({ commit }, data) {
      // upd lang & upd it in localStorage
      commit('SET_LANG', data);
      // let storageHeaders = {};
      // try {
      //   storageHeaders = JSON.parse(localStorage.getItem('headers'));
      // } catch (e) {
      //   console.log(e);
      // }
      // localStorage.setItem('headers', JSON.stringify({ ...storageHeaders, Lang: data }));
    },
    addModal({ commit }, modal) {
      // show modal in App component
      const uuid = this._vm.$uuid();
      const data = {
        uuid,
        ...modal,
      };
      commit('ADD_MODAL', data);
    },
    getBreakpoint({ state, commit }) {
      if ('window' in global) {
        const gtOrEqual = {
          xs: true,
          sm: true,
          md: true,
          lg: true,
          xl: true,
        };
        ['xl', 'lg', 'md', 'sm', 'md', 'xs'].some((size) => {
          const value = window.getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint-${size}`);
          const bp = window.matchMedia(`(min-width: ${value})`).matches;
          gtOrEqual[size] = bp;
          return bp;
        });
        if (!this._vm.$lodash.isEqual(state.breakpoint, gtOrEqual)) commit('SET_BREAKPOINT', gtOrEqual);
      }
    },
  },
  getters: {
    bgNoScroll: (state) => state.modals.length > 0,
  },
  modules: {
    settings,
    partnerCodeModule,
  },
});
