import Cookies from 'js-cookie';
import { callApi } from '@/api';
import LogService from '@/services/LogService';

export default {
  namespaced: true,
  state: {
    loginApi: '/api/user/magic/login?cookie_login=1',
    logoutApi: '/api/user/logout',
    partnerCode: Cookies.get('partner_code'),
  },
  mutations: {
    SET_PARTNER_CODE(state, code) {
      state.partnerCode = code;
      Cookies.remove('partner_code');
      Cookies.set('partner_code', code, { secure: true, sameSite: 'None', expires: 400 });
    },
    DEL_PARTNER_CODE(state) {
      state.partnerCode = null;
      Cookies.remove('partner_code');
    },
  },
  actions: {
    async login({ commit, rootState, state }, $route) {
      const magicCode = Cookies.get('magic_code');
      let loginSuccessful;

      if (magicCode) {
        const headers = {
          'Magic-Code': magicCode,
        };
        const res = await callApi({ url: state.loginApi, headers }, { commit, state: rootState })
          .catch(() => {
            // if error than remove magic_code from cookies
            Cookies.remove('magic_code');
            return {};
          });
        loginSuccessful = res.data?.partner_code;
      }

      /* eslint-disable-next-line no-underscore-dangle */
      const partner_code_update = $route.query.partner_code_update === '1';
      const url_partner_code = $route.query.partner_code;
      const cookiePartnerCode = Cookies.get('partner_code');
      const default_partner_code = process.env.VUE_APP_SERVER_VERSION
        ? process.env.VUE_APP_SERVER_VERSION.toLowerCase() : 'wetterheld';

      /**
       * url parameter partner_code_update=1 defined | url parameter partner_code defined
       *   | successful cookie login performed | partner_code cookie defined
       */
      const prio = [
        [true, true, undefined, undefined, url_partner_code], /** url parameter partner_code */
        [true, false, undefined, undefined, default_partner_code], /** default partner_code */
        [false, undefined, true, undefined, loginSuccessful], /** response body cookie login */
        [false, undefined, false, true, cookiePartnerCode], /** partner code cookie */
        [false, true, false, false, url_partner_code], /** url paramter partner_code */
        [false, false, false, false, default_partner_code], /** default partner_code */
      ];

      let partnerCode = null;

      for (let i = 0; i < prio.length; i += 1) {
        const prios = prio[i];
        const check = [
          prios[0] === undefined || !!partner_code_update === prios[0],
          prios[1] === undefined || !!url_partner_code === prios[1],
          prios[2] === undefined || !!loginSuccessful === prios[2],
          prios[3] === undefined || !!cookiePartnerCode === prios[3],
        ];
        if (check.every((r) => r === true)) {
          [, , , , partnerCode] = prios;
          break;
        }
      }
      LogService.log('partner code module set partner code', partnerCode);
      commit('SET_PARTNER_CODE', partnerCode);
    },
    async set_partner_code({ commit }, partnerCode) {
      LogService.log('partner code module set partner code', partnerCode);
      commit('SET_PARTNER_CODE', partnerCode);
    },
    async logout({ commit, rootState, state }) {
      // logout user
      return callApi(state.logoutApi, { commit, state: rootState })
        .finally(() => {
          // clear from cookie partner code
          const partnerCodeCookie = Cookies.get('partner_code');
          const magicCode = Cookies.get('magic_code');
          if (partnerCodeCookie) {
            commit('DEL_PARTNER_CODE');
          }
          if (magicCode) {
            Cookies.remove('magic_code');
          }
        });
    },
  },
};
