<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="wet-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
              <form @submit.prevent="submit">
                <div
                  class="wet-input wet-input-with-label"
                  :class="$v.form.email.$error ? 'wet-input-error' : ''"
                >
                  <input
                    id="wet-input-email"
                    type="text"
                    :placeholder="strings.commonApp && strings.commonApp.email"
                    v-model.trim="$v.form.email.$model"
                  >
                  <label for="wet-input-email">
                    {{ strings.commonApp && strings.commonApp.email }}
                  </label>
                </div>
                <div
                  v-if="$v.form.email.$error && $v.form.email.taken"
                  class="small text-primary mt-n2 mb-3 mx-3"
                >
                  {{ strings.commonApp && strings.commonApp.error_email }}
                </div>
                <div v-if="!$v.form.email.taken" class="small text-primary mt-n2 mb-3 mx-3">
                  {{ strings.commonApp && strings.commonApp.error_email_already_used }}
                </div>
                <div class="form-grouped d-flex">
                  <div
                    class="nice-select"
                    id="phone-code-select"
                    :class="codeSelectOpen ? 'open' : ''"
                    @click="codeSelectOpen = !codeSelectOpen"
                    style="min-width:auto;"
                  >
                    <span class="current">
                      {{(phoneCodes.filter((c) => c.code === form.phoneCode)[0] || {}).text}}
                    </span>
                    <ul class="list">
                      <li
                        class="option"
                        :id="`phone-code-option-${id}`"
                        :class="(form.phoneCode || '')
                          .toString() === item.code ? 'selected focus' : ''"
                        v-for="(item, id) in phoneCodes"
                        :key="id"
                        @click="$set(form, 'phoneCode', item.code)"
                        :title="item.country"
                      >{{item.text}}</li>
                    </ul>
                  </div>
                  <div
                    class="wet-input wet-input-with-label w-100"
                    :class="$v.form.phone.$error ? 'wet-input-error' : ''"
                  >
                    <input
                      id="wet-input-phone"
                      type="tel"
                      inputmode="tel"
                      :placeholder="strings.commonApp && strings.commonApp.phone"
                      v-model="$v.form.phone.$model"
                    >
                    <label for="wet-input-phone">
                      {{ strings.commonApp && strings.commonApp.phone }}
                    </label>
                  </div>
                </div>
                <div v-if="$v.form.phone.$error" class="small text-primary mt-n2 mb-3 mx-3">
                  {{ strings.commonApp && strings.commonApp.error_phone }}
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="wet-input wet-input-with-label">
                      <input
                        id="wet-input-firstname"
                        type="text"
                        :placeholder="strings.commonApp && strings.commonApp.firstname"
                        v-model.trim="$v.form.firstname.$model"
                      >
                      <label for="wet-input-firstname">
                        {{ strings.commonApp && strings.commonApp.firstname }}
                      </label>
                    </div>
                    <div v-if="$v.form.firstname.$error" class="small text-primary mt-n2 mb-3 mx-3">
                      {{ strings.commonApp && strings.commonApp.error_firstname }}
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="wet-input wet-input-with-label">
                      <input
                        id="wet-input-lastname"
                        type="text"
                        :placeholder="strings.commonApp && strings.commonApp.lastname"
                        v-model.trim="$v.form.lastname.$model"
                      >
                      <label for="wet-input-lastname">
                        {{ strings.commonApp && strings.commonApp.lastname }}
                      </label>
                    </div>
                    <div v-if="$v.form.lastname.$error" class="small text-primary mt-n2 mb-3 mx-3">
                      {{ strings.commonApp && strings.commonApp.error_lastname }}
                    </div>
                  </div>
                </div>
                <label
                  id="wet-checkbox1-label"
                  class="checkbox is-size-7 my-3"
                  for="wet-checkbox1"
                >
                  <strong class="empty-ph">
                    {{strings.letus && strings.letus.checkbox1}}
                  </strong>
                  <input
                    type="checkbox"
                    id="wet-checkbox1"
                    class=""
                    v-model="form.checkbox1"
                  >
                  <span id="wet-checkbox1-span"></span>
                </label>
                <!-- <div v-if="$v.form.checkbox1.$error" class
                ="small text-primary mt-n2 mb-3 mx-3">
                  {{ strings.commonApp && strings.commonApp.error_checkbox }}
                </div> -->
                <label
                  id="wet-checkbox2-label"
                  class="checkbox is-size-7 my-3"
                  for="wet-checkbox2"
                >
                  <strong class="empty-ph" v-html="strings.letus && strings.letus.checkbox2">
                  </strong>
                  <input
                    type="checkbox"
                    id="wet-checkbox2"
                    class=""
                    v-model="$v.form.checkbox2.$model"
                  >
                  <span id="wet-checkbox2-span"></span>
                </label>
                <div v-if="$v.form.checkbox2.$error" class="small text-primary mt-n2 mb-3 mx-3">
                  {{ strings.commonApp && strings.commonApp.error_checkbox }}
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import {
  required,
  email,
} from 'vuelidate/lib/validators';
import {
  phone,
  firstname,
  lastname,
} from '@/common/validations';
import wpSection from '@/enums/wpSection';
import metaData from '@/enums/websiteMeta';

export default {
  mixins: [common],
  props: {
    nextDisabled: Boolean,
  },
  data() {
    return {
      letUsApi: '/api/user/register',
      userStatusApi: '/api/user/status',
      form: {
        email: '',
        phoneCode: '49',
        phone: '',
        firstname: '',
        lastname: '',
        checkbox1: false,
        checkbox2: false,
      },
      codeSelectOpen: false,
      progress: [2, 5],
      wpAdditionalApi: '/wp-json/acf/v3/options/letus',
      emailTaken: false,
      userStatusData: {},
    };
  },
  computed: {
    ...mapState(['strings', 'lang', 'order']),
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
          taken: () => !this.emailTaken,
        },
        phone,
        firstname,
        lastname,
        // checkbox1: {
        //   checked: (val) => val,
        // },
        checkbox2: {
          checked: (val) => val,
        },
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit('update:nextDisabled', Object.entries(val)
          .filter((el) => el[0] !== 'checkbox1').some((el) => !el[1]) || this.$v.$anyError);
      },
    },
    'form.email': {
      handler() {
        this.emailTaken = false;
      },
    },
  },
  async created() {
    await Promise.all([
      this.$store.commit('SET_APP_LAYOUT', { progress: this.progress }),
      this.getWordPressStrings([wpSection.LETUS], wpSection.LETUS),
      this.$root.$on('buttonNext', this.next),
      this.$root.$on('buttonBack', this.back),
      this.getUserStatus(),
    ]);
    await this.pushDataLayer();
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) return;
      const data = {
        user: {
          email: this.form.email,
        },
        profile: {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          phone: this.form.phone,
          phone_code: this.form.phoneCode,
        },
      };
      await this.callApi({ url: this.letUsApi, method: 'put', data })
        .then((res) => {
          // save magic_code
          if (res.data.magic_code) this.$Cookies.set('magic_code', res.data.magic_code, { secure: true, sameSite: 'None', expires: 365 });
          // redirect to done
          this.$router.push({ name: 'done', params: { ...this.$route.params } });
        })
        .catch((error) => {
          if (error.response?.data?.errors?.['user.email']?.[0] === 'The user is already exists.') {
            this.emailTaken = true;
          } else console.log(error);
        });
    },
    back() {
      // this.$router.push({ name: 'location', params: { ...this.$route.params } });
      this.$router.back();
    },
    next() {
      this.submit();
    },
    /** always push path such as '/home/letus-business_type' */
    pushDataLayer() {
      const path = `${this.$route.path.replace(/\/$/, '')}-${this.userStatusData.business?.type}`;
      const dataLayerItem = {
        event: 'Pageview',
        pagePath: path,
        pageTitle: metaData.letus[this.lang].title,
        originalLocation: `${document.location.protocol}//\
${document.location.hostname}\
${path}`,
      };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerItem);
    },
    async getUserStatus() {
      const res = await this.callApi({ url: this.userStatusApi, method: 'GET' });
      this.userStatusData = res.data;
    },
  },
};
</script>
